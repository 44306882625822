
















import { Component, Vue, Prop } from 'vue-property-decorator'
import Widget from '@/components/Widget/Widget.vue'
import UserForm from './components/UserForm.vue'

@Component({
  components: {
    Widget,
    UserForm,
  },
})
export default class UserEdit extends Vue {
  @Prop()
  public id!: string;

  public title: string = 'Create User';

  public created() {
    if (this.id) {
      this.title = 'Edit User'
    }
  }
}
