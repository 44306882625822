
























































import Widget from '@/components/Widget/Widget.vue'
import { Component, Ref } from 'vue-property-decorator'
import User from '@/models/User'
import ViewModel from '@/models/ViewModel'
import IconAction from '@/components/IconAction/IconAction.vue'
import SearchInput from '@/components/SearchInput/SearchInput.vue'

import { getModule } from 'vuex-module-decorators'
import SystemtModule from '@/store/SystemModule'
import DataTable from '@/components/DataTable/index.vue'
import { clone } from 'lodash'
import DataTableField from '@/components/DataTable/DataTableField'

const { user } = getModule(SystemtModule)

@Component({
  components: {
    Widget,
    IconAction,
    SearchInput,
    DataTable,
  },
})
export default class UserHome extends ViewModel {
  // dataTable Ref
  @Ref() readonly dataTable!: HTMLFormElement

  // Target user during action event
  public target: User = new User()

  // Total Number of records
  public records: number = 0

  // Search Input
  public query: string[] = []

  // Loading incicator
  public loading: boolean = false

  // dataTable field filters
  public fieldFilters: any = {}

  // table data
  public table_data: any = []

  // Selected rows
  public selected: string[] = []

  // Indicates is component is initialized and ready to load data
  public ready = false

  public modal: any = {
    delete: false,
    reset: false,
  }

  // Available fields to show in table, set on mounted
  public fields: Array<DataTableField> = []

  // Filter Options
  public query_settings: any = {
    company_fields: [
      {
        name: 'company',
        key: 'company_id',
      },
      {
        name: 'station',
        key: 'station_id',
        type: 'station',
      },
    ],
    profile_fields: [
      {
        name: 'profile',
        key: 'profile_id',
      },
    ],
    custom_fields: [
      {
        name: 'station:n/a',
        value: 'station_id:',
      },
    ],
  }

  // Initialize vars
  public mounted() {
    this.fields = clone(User.tableFields)
    this.loadFilters()
  }

  public async rows(context: any): Promise<any> {
    this.loading = true
    const field_filters = Object.keys(this.fieldFilters)
      .filter((key: string) => this.fieldFilters[key] !== '')
      .map((key: string) => `${key}:${this.fieldFilters[key].toLowerCase()}`)

    this.syncFilters()
    return User.paginate({
      page_size: context.perPage,
      page: context.currentPage,
      order_by: context.sortBy,
      order: context.sortDesc ? 'desc' : 'asc',
      query: [...context.filter, ...field_filters],
    }).then(result => {
      this.records = result.records
      this.loading = false
      this.table_data = result.data
      return result.data
    })
  }

  // Store session filters in VUEX
  public syncFilters() {
    const system = getModule(SystemtModule)
    system.updateState({
      name: 'filters',
      type: 'users',
      data: { query: this.query, fieldFilters: this.fieldFilters },
    })
  }

  // Load filters from VUEX if present
  public loadFilters() {
    const system = getModule(SystemtModule)
    system.getFilter('users').then((filter: any) => {
      if (filter) {
        this.query = filter.query
        this.fieldFilters = filter.fieldFilters
      }
      this.ready = true
    })
  }

  // Clear VUEX filters state
  public clearFilters() {
    const system = getModule(SystemtModule)
    system.updateState({
      name: 'filters',
      type: 'users',
      data: null,
    })
  }

  // Refresh dataTable
  public refresh() {
    this.dataTable.refresh()
  }

  // Reset filters
  public resetFilters() {
    this.fieldFilters = {}
    this.query = []
    this.clearFilters()
    this.refresh()
  }

  // Return active fields to the table
  public get show_fields() {
    return this.fields.filter((f: any) => f.show)
  }

  // Redirect user to the create page
  public createUser() {
    this.$router.push('/app/user')
  }
}
